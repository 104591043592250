.round-text {
	border-radius: 5px !important;
	max-width: 90vw;
	// width: 400px;
}

.password-validator-msg-container {
	display: flex;
	justify-content: center;
}

.div80vh {
	min-height: 70vh;
	overflow: auto;
}

.login-form {
	max-width: 90vw;
	width: 600px;
}

.ant-row .ant-form-item {
	margin-bottom: 12px !important;
}

.ant-form-item {
	margin-bottom: 12px !important;
}

.d-flex {
	display: flex !important;
	height: 100vh;
}

.ant-input:focus {
	border-color: #ff9300;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
	box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

.center {
	width: 800px;
}

.login-form-forgot {
	float: right;
}

.ant-col-rtl .login-form-forgot {
	float: left;
}

.login-form-button {
	width: 100%;
	color: #ff9300;
	background-color: #ff9300;
	border-radius: 10px !important;
}

.error-alert-msg {
	.ant-alert-message {
		color: #fff;
	}
}

.primary-btn:disabled {
	background: #f5f5f5 !important;
}

.password-validator-msg-container > ul ::marker {
	color: #212121;
}

.list {
	list-style: none;
}

.password-validator-msg-container > ul {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 0 1rem;
	width: 100%;
	list-style: disc;
}

li {
	text-align: left;
}

.listStyleDisabled {
	padding: 0;
}

.listStyleDisabled > li {
	list-style: none !important;
}

.hide-icons {
	display: none !important;
}

.social-btn:hover {
	background: #d9d9d9 !important;
	color: #212121 !important;
	border-color: #d9d9d9 !important;
}

.round-text > .ant-select-selector {
	height: 40px !important;
	align-items: center !important;
	border-radius: 5px !important;
}

.country>div>span:first-child {
	display: flex !important;
	align-items: center !important;
}
.login-card-container > .ant-card-body {
	padding: 0% !important;
}

.footer-row {
	width: 80%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 16px;
}

@media screen and (max-width: 1000px) {
	.footer-row {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	.all-right-reserved {
		grid-column-start: 1;
		grid-column-end: 4;
	}
}
@media screen and (max-width: 600px) {
	.footer-row {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	.all-right-reserved {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}
