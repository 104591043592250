@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "./variables";

h1.bp3-heading {
  color: #137cbd;
  margin-bottom: 20px;
}

.bp3-card {
  margin-bottom: 20px;
}

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

hr {
  color: #ccc;
  box-shadow: 0 0 0 0 transparent;
  margin: 20px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.pr-1 {
  padding-right: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.field-error {
  text-align: left;
  color: $error-color;
}

.table-bordered {
  border: 1px solid #d2d5d8;
}

.br-1 {
  border-radius: 4px;
}

.col-3 {
  width: 25%;
}

.col-9 {
  width: 75%;
}

.no-padding {
  padding: 0 !important;
}

.dsg-cell-header {
  justify-content: center;
  display: flex;
  align-items: center;
  width: "100%";
}

.dsg-cell-header-container {
  text-overflow: wrap;
  font-size: small;
  color: black;
}

.relative {
  position: relative;
}

.page__container .ant-space-vertical {
  gap: 0px !important;
}

.ant-page-header-footer {
  margin: 0 !important;
}

.ant-tabs-tab {
  padding: 0.5rem 0rem !important;
}

.ant-collapse-expand-icon {
  padding: 0 16px 0 0 !important;
  margin: 0 !important;
}

.ant-collapse-header {
  padding: 12px 16px !important;
}

.ant-table-title {
  padding: 8 16 !important;
  margin-bottom: 16 !important;
}

.ant-list-header {
  padding: 0px !important;
}

.ant-table-cell {
  padding: 5px 8px !important;
}

.ant-table-title {
  padding: 0.5rem !important;
  border: none !important;
}

.ant-table-container {
  border: none !important;
}

.ant-table-cell {
  border-left: none;
  border-right: 1px solid rgb(233, 233, 231) !important;
}