@import "../../styles/variables";

.login-page-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  background-color: $white-color;
  box-sizing: border-box;
  overflow: auto;

  &.signup-page {
    .login-form-container {
      h1 {
        span {
          color: $blue-color;
        }
      }
    }
  }

  .login-form-container {
    width: 100%;
    padding: 1rem 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;

    .form-holder {
      /*max-width: 360px;
      min-width: 270px;*/
    }

    h1 {
      font-size: 28px;
      font-weight: bold;
      color: $black-color;
      text-align: center;
      padding: 1.5rem 0 1rem 0;

      span {
        color: $orange-color;
      }
    }

    .login-additional-functions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2rem;

      .remember-me label {
        margin-bottom: 0;
      }
    }
  }

  .login-info-container {
    display: none;
  }
}

.login-footer-actions {
  display: flex;
  flex-direction: column;
  align-items: center;

  div,
  button {
    width: 168px;
    text-align: center;
  }

  div {
    padding: 1rem 0;
  }
}

@media screen and (min-width: 768px) {
  .login-page-wrapper {
    .login-form-container {
      width: 41%;
      padding: 1rem 1.5rem 3rem;

      .form-holder {
        /*max-width: 270px;*/
      }

      h1 {
        font-size: 28px;
        font-weight: bold;
        color: $black-color;
        text-align: center;
        padding: 4rem 0 3rem 0;

        span {
          color: $orange-color;
        }
      }

      .login-additional-functions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2rem;

        .remember-me {
          margin-bottom: 0;
        }
      }
    }

    .login-info-container {
      display: block;
      color: $white-color;
      width: 60%;
      border-right: 1px solid $orange-color;
      float: right;
      background: url(../../assets/imgs/login-info-bg.png) no-repeat $orange-color;
      background-size: cover;
      padding: 3rem 6rem 1rem;

      h2 {
        font-weight: normal;
        font-size: 43px;
        padding-bottom: 0.6rem;

        span {
          font-size: 49px;
          font-weight: bold;
        }
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        text-align: justify;
        text-justify: inter-word;
      }
    }
  }

  .signup-page {
    .login-info-container {
      border-right: 1px solid $blue-color;
      background: url(../../assets/imgs/login-info-bg.png) no-repeat $blue-color;
    }
  }
}

.bp3-button:not([class*="bp3-intent-"]).gmail-signup {
  background-color: #cf4332;
  color: #fff;
  background-image: none;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 30px;
  border: 1px solid #ae3223;

  .bp3-button-text {
    display: flex;
    align-items: center;
  }

  .gmail-icon {
    background: url(../../assets/imgs/icons/gmail-login.jpg) center center / 24px no-repeat;
    margin-right: 5px;
    width: 26px;
    height: 24px;
    margin-right: 5px;
  }
}