.children-container {
	background-color: #f5f5f5;
	min-height: 100%;
	padding: 25px 20px;
}

.navbar-group {
	width: 100%;
}

.navbar-items {
	display: flex;
	justify-content: flex-end;
	float: none;
	width: 100%;
}

.styled-sider {
	// border-radius: 5px !important;
	background: none !important;
	border-right: 1px solid white !important;
	box-shadow: 24px 24px 24px 24px #617c960a !important;

	.ant-menu {
		background: none;
	}

	.ant-layout-sider-light {
		background: none !important;
	}

	.ant-layout-sider-trigger {
		background: none !important;
	}
}


// .celsure_segment .ant-segmented-item,
// .pcm_segment .ant-segmented-item {
// 	padding: 5px !important;
// }

.celsure_segment .ant-segmented-item-selected {
	background: #e6f4ff !important;
	border: 1px solid #bcdfff
}

.pcm_segment .ant-segmented-item-selected {
	background: #fefbe6 !important;
	border: 1px solid #fff0bb
}

.project-selection {
	width: 100%;

	.ant-select-selector {
		height: 60px !important;
		background-color: #1890ff !important;
		color: white;
		font-weight: bold;
		border-radius: 12px !important;
		padding-left: 28px !important;
			padding-right: 28px !important;
		padding-top: 24px !important;
		padding-bottom: 12px !important;

		.ant-select-selection-item {
			height: 40px;
		}

		&::before {
			content: attr(data-label);
			position: absolute;
			top: 12px;
			font-size: 12px;
			font-weight: bold;
			color: white !important;
		}
	}

	.ant-select-arrow {
		color: white;
		margin-right: 12px !important;
	}
}

.project-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	width: 8em;
}

.projects-title-menu {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block !important;
	width: 12em;
}
.project-selection>div>span.ant-select-selection-item {
	color: white !important;
}

.project-selection>div>span.ant-select-selection-item {
	color: white !important;
}